<!-- 完成或未完成列表表头 -->
<template>
  <div class="header">
    <div class="return">
      <div class="return-icon" @click="handleBack">
        <Icon type="ios-arrow-back" />
      </div>
      <span>数据目录</span>
      <span> / 复核记录</span>
    </div>
    <!-- <ul>
      <li :class="isShow == 0 ? 'active' : ''" @click="handleNav(0)">
        一般({{ unCount }})
      </li>
      <li :class="isShow == 1 ? 'active' : ''" @click="handleNav(1)">
        重要({{ count }})
      </li>
    </ul> -->
    <div class="button">
        <CommonHeader/>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, defineEmits ,inject ,onMounted} from "vue";
import { useRouter } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue"
import bus from "@/utils/bus.js";
let isEmail = ref(false);
let isPerson = ref(false);
let isShow = ref(0); //0代表未完成，1代表已完成

let emit = defineEmits(["showNav"]);
function handleNav(val) {
  isShow.value = val;
  emit("showNav", isShow.value);
}

let router = useRouter();
const history = () => {
  // router.push("addFillType");
};
let unCount = ref(0);
let count = ref(0);
// bus.on("count", (val) => {
//   count.value = val;
// });
// bus.on("unCount", (val) => {
//   unCount.value = val;
// });

const handleBack = () => {
  router.back();
};
let axios = inject("axios");
const getCount = () => {
  let url = `/audit/recordCount`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      count.value = data.important;
      unCount.value = data.general;
    }
  });
};
onMounted(() => {
  // getCount();
});
</script>
  
  
  <style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  .return {
    display: flex;
    align-items: center;
    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      i {
        font-size: 18px;
        color: #000;
      }
    }
    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }
    span:last-child {
      color: #cccccc;
    }
  }
  ul {
    display: flex;
    margin-left: -170px;
    li {
      cursor: pointer;
      color: #888888;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      height: 40px;
      width: 150px;
      letter-spacing: 0px;
      text-align: center;
    }
    li.active {
      background: #f6f6f6;
      border-radius: 10px;
      color: #111111;
      font-weight: bold;
    }
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn-add {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
.custom-btn {
  border: 1px solid #606060;
  color: #606060;
  border-radius: 6px;
  padding: 3px 20px;
  font-size: 14px;
}
</style>